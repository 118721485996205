import { createAction, props } from '@ngrx/store';
import { SiteCategories } from '../../../../typings/api';
import { LocaleType } from '../../models/locales.models';

export const setLanguage = createAction(
    '[Site Language] Set Language',
    props<{ language: LocaleType }>()
);
export const setSiteCategorie = createAction(
    '[Site Categories] Set Site Categories',
    props<{ siteCategories: SiteCategories }>()
);
